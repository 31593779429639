import React from "react";
import { Link } from "react-router-dom";
import {
  Text,
  Figure,
  Button,
  FrameCorners,
  FrameHexagon,
  CodeBlock,
} from "@arwes/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import Modules from "../modules";

const IMAGE_URL = process.env.PUBLIC_URL + "images/map.png";

const Home = () => {
  const activate = true;
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var curHour = today.getHours();
  var curMinute = today.getMinutes();
  today = dd + "/" + mm;
  return (
    <div>
      <CodeBlock animator={{ activate }} lang="tsx">
        {`${today + " " + curHour + ":" + curMinute} $Analysis-Center. 
Position: WithWoods Lab.
Status: active
System: There's no time for panic`}
      </CodeBlock>

      <Figure src={IMAGE_URL} alt="A nebula">
        La mappa satellitare di WithWoods, non farti scappare alcun dettaglio.
      </Figure>
      <div className="modules-buttons text-center">
        {Modules.map((module) => {
          return (
            <Link to={module.route}>
              <Button FrameComponent={FrameCorners}>
                <Text>{module.button}</Text>
              </Button>
            </Link>
          );
        })}

        <div className="soirvelance">
          <a
            target="_blank"
            href="https://www.google.it/maps/@45.2290559,11.8690614,3a,75y,72.07h,89.14t/data=!3m6!1e1!3m4!1sfI4AbzHCFZ_IYdWSy4DfLw!2e0!7i13312!8i6656"
            rel="noreferrer"
          >
            <Button palette="success" FrameComponent={FrameHexagon}>
              <Text>
                <FontAwesomeIcon icon={faVideo} /> N-West
              </Text>
            </Button>
          </a>

          <a
            target="_blank"
            href="https://www.google.it/maps/@45.2279065,11.8690281,3a,75y,257.25h,71.37t/data=!3m7!1e1!3m5!1stGy7KoDEb9rsKy8xwDl0DQ!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DtGy7KoDEb9rsKy8xwDl0DQ%26cb_client%3Dmaps_sv.tactile.gps%26w%3D203%26h%3D100%26yaw%3D132.28218%26pitch%3D0%26thumbfov%3D100!7i13312!8i6656"
            rel="noreferrer"
          >
            <Button palette="secondary" FrameComponent={FrameHexagon}>
              <Text>
                <FontAwesomeIcon icon={faVideo} /> S-West
              </Text>
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
