const Modules = [
  {
    route: "alpha",
    title: "Alpha analysis",
    button: "Modulo Aplha",
    image: { src: "testimone.png", description: "Target individuato" },
    imageResponse: { src: "albero.jpeg", description: "La dimora" },
    result: 152,
    placeholder: "Codice d'accesso",
    textSuccess:
      "Il testimone pensa che l'origine del virus e di alcuni focolai nella zona non siano dovuti al furto del virus dal laboratorio ma da un salto interspecie animale/uomo. Dice che una vecchia signora che abita nelle vicinanze sia l'origine di tutto. Ha fatto di un albero la sua dimora dove convive con certi animali tropicali. Correte ad indagare",
  },
  {
    route: "bravo",
    title: "Bravo analysis",
    button: "Modulo Bravo",
    imageResponse: { src: "bici.png", description: "Il mezzo individuato" },
    result: 65871235,
    placeholder: "Sequenza genetica",
    textSuccess:
      "Charlie: La sequenza dna ha dato esito positivo. Sono stati trovati focolai derivanti da questa variante. L'anziana signora si sarà sicuramente spostata e con se ha trasportato il virus. Dobbiamo tracciare i suoi spostamenti grazie al nostro sistema di sorveglianza. Accedi alle telecamere poste a nord-ovest, si vocifera che si sposta con una piccola bicicletta, è essenziale inserire il colore della bicicletta per tracciarne i movimenti",
  },
  {
    route: "charlie",
    title: "Charlie analysis",
    button: "Modulo Charlie",
    image: { src: "bici.png", description: "Il mezzo individuato" },
    imageResponse: {
      src: "cluster.png",
      description: "Il nuovo focolaio scoperto",
    },
    result: [
      "azzurra",
      "azzurro",
      "blu",
      "blu metallizzato",
      "azzurra metallizzato",
    ],
    placeholder: "Colore",
    textSuccess:
      "Intrecciando i vari dati abbiamo scoperto che l'anziana donna, frequentando gli spazi olimpici della città per assistere alle gare atletiche del nipote, ha dato vita ad il primo cluster del virus. Non c'è tempo da perdere, bisogna spegnere il focolaio prima che divampi. Sbrigatevi!",
  },
  {
    route: "delta",
    title: "Delta analysis",
    button: "Modulo Delta",
    imageResponse: {
      src: "politico.jpeg",
      description: "Porta la pace nel mondo",
    },
    result: "canyoufigurethisout254",
    placeholder: "Codice allarme",
    textSuccess:
      "Allarme innescato. In men che non si dica si è alzato un polverone mediatico internazionale. Tutti i partiti politici se le danno di santa ragione scaricandosi la colpa gli uni con gli altri. Armatevi di buon senso e andate a mitigare le acque politiche internazionali in parlamento!",
  },
  {
    route: "echo",
    title: "Echo analysis",
    button: "Modulo Echo",
    result: "spiritoponterefuga8264",
    placeholder: "Il discorso perfetto",
    textSuccess:
      "Il discorso è un successo! Dirigetevi al laboratorio per accedere per primi all'antidoto!",
  },
];
export default Modules;
