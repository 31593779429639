import { React, useState } from "react";
import { Link } from "react-router-dom";
import {
  Text,
  CodeBlock,
  Button,
  FrameHexagon,
  Blockquote,
  LoadingBars,
  FrameLines,
  Figure,
} from "@arwes/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";

const activate = true;
var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0");
var curHour = today.getHours();
var curMinute = today.getMinutes();
today = dd + "/" + mm;

const Feedback = ({ correct, textSuccess, imageResponse }) => {
  const response = correct ? (
    <div>
      {imageResponse && (
        <Figure
          src={process.env.PUBLIC_URL + "images/" + imageResponse.src}
          alt="A nebula"
        >
          {imageResponse.description}
        </Figure>
      )}
      <Blockquote palette="success">
        <Text>{textSuccess}</Text>
      </Blockquote>
      <div className="home-button text-center">
        <Link to="/">
          <Button FrameComponent={FrameLines}>
            <Text>
              <FontAwesomeIcon icon={faTachometerAlt} /> Analysis Center
            </Text>
          </Button>
        </Link>
      </div>
    </div>
  ) : (
    <Blockquote palette="error">
      <Text>Risposta errata. Ritenta.</Text>
    </Blockquote>
  );
  return <div>{response}</div>;
};

const InputContent = ({ onSubmit, setValue, value, placeholder }) => {
  return (
    <div>
      <input
        autoFocus
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className="module-accept text-center">
        <Button onClick={onSubmit} FrameComponent={FrameHexagon}>
          <Text>Elabora</Text>
        </Button>
      </div>
    </div>
  );
};
//vans
//4
//Codice mancante 32
//4x(32+6) = 152
const Module = ({
  result,
  textSuccess,
  title,
  placeholder,
  image,
  imageResponse,
}) => {
  const [value, setValue] = useState("");
  const [correct, setCorrect] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  var text = "Waiting for user input";

  if (loading) text = "Processing";
  else if (correct) text = "Response accepted";
  const onSubmit = async () => {
    setLoading(true);
    setSubmitted(true);
    // alert("ok");

    await setTimeout(() => {
      setLoading(false);
    }, 1500);

    if (Array.isArray(result)) {
      result.forEach((element) => {
        if (value == element) setCorrect(true);
      });
    } else if (value == result) setCorrect(true);
    else setCorrect(false);
  };

  return (
    <div>
      <div className="text-center">
        <Text as="h2">{title}</Text>
      </div>
      <div>
        <CodeBlock animator={{ activate }} lang="tsx">
          {`${today + " " + curHour + ":" + curMinute} $Analysis-Center. 
Status: active
System: ${text}`}
        </CodeBlock>
        {image && !correct && (
          <Figure
            src={process.env.PUBLIC_URL + "images/" + image.src}
            alt={image.description}
          >
            {image.description}
          </Figure>
        )}
        {loading ? (
          <div className="text-center">
            <LoadingBars />
          </div>
        ) : (
          <div>
            {submitted && (
              <Feedback
                textSuccess={textSuccess}
                imageResponse={imageResponse}
                correct={correct}
              />
            )}
            {!correct && (
              <InputContent
                placeholder={placeholder}
                onSubmit={onSubmit}
                setValue={setValue}
                value={value}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Module;
