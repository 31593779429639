import React, { FC } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  ArwesThemeProvider,
  StylesBaseline,
  Button,
  Text,
  FrameLines,
} from "@arwes/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";

import "./style.css";
import Home from "./Pages/Home";
import Module from "./Pages/Module";
import Modules from "./modules";

const ROOT_FONT_FAMILY = '"Titillium Web", sans-serif';

const App: FC = () => {
  return (
    <Router>
      <div className="container">
        <ArwesThemeProvider>
          <StylesBaseline styles={{ body: { fontFamily: ROOT_FONT_FAMILY } }} />
          <div className="home-button text-center">
            <Link to="/">
              <Button FrameComponent={FrameLines}>
                <Text>
                  <FontAwesomeIcon icon={faTachometerAlt} /> Analysis Center
                </Text>
              </Button>
            </Link>
          </div>
          <Switch>
            {Modules.map((module, index) => {
              return (
                <Route path={"/" + module.route}>
                  <Module {...module} />
                </Route>
              );
            })}

            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </ArwesThemeProvider>
      </div>
    </Router>
  );
};
export default App;
